import {
  LoginInterface,
  SettingsInterface,
  SignupInterface,
  SubscriptionPlan,
  UserInterface,
} from '../interfaces/auth'
import routes from '../routes'
import { api } from './index'
import { useFetch } from '@utils/reactQuery'

export const login = async (value: LoginInterface) => {
  return api.post<UserInterface>(routes.api.login, value)
}

export const requestResetPassword = async (email: string) =>
  api.post<LoginInterface>(routes.api.user.resetPassword, {
    email,
  })

export const verifyResetPassword = async (token: string, password: string) =>
  api.post<LoginInterface, { token: string; password: string }>(
    routes.api.user.verifyResetPassword,
    {
      token,
      password,
    },
  )

export const getProfile = async auth => api.get<UserInterface>(routes.api.user.me, auth)

export const useGetProfile = () => useFetch<UserInterface>(routes.api.user.me)
export const useGetPromoInfo = () =>
  useFetch<{ type: 'free' | 'free3M' | 'free2M' | null }>(routes.api.user.promoInfo)

export const signup = async (value: SignupInterface) =>
  api.post<UserInterface>(routes.api.user.create, value)

export const logout = async () => api.post(routes.api.logout)

export const updateProfile = async (password: string) =>
  api.patch<UserInterface, { password: string }>(routes.api.user.update, {
    password,
  })

export const closeHeaderPromoBanner = async () =>
  api.patch<UserInterface, { promoHeaderBannerClosed: boolean }>(routes.api.user.update, {
    promoHeaderBannerClosed: true,
  })

export const applyPromoCode = async (promo: string) =>
  api.patch(routes.api.user.applyPromocode, {
    promo,
  })

export const updateOnboarding = async () =>
  api.patch<UserInterface>(routes.api.user.update, {
    onboardingCompleted: true,
  })

export const updateComparePlansOnboarding = async () =>
  api.patch<UserInterface>(routes.api.user.update, {
    comparePlansOnboardingCompleted: true,
  })

export const updateDoNotShowAfterPublishOnboarding = async (checked: boolean) =>
  api.patch<UserInterface, UserInterface>(routes.api.user.update, {
    doNotShowAfterPublishOnboarding: checked,
  })

export const updateFaqOnboarding = async () =>
  api.patch<UserInterface>(routes.api.user.update, {
    faqSectionOnboardingCompleted: true,
  })

export const updateABTestsOnboarding = async () =>
  api.patch<UserInterface>(routes.api.user.update, {
    abTestsOnboardingCompleted: true,
  })

export const requestVerifyEmail = async () => api.post(routes.api.user.requestVerifyEmail)

export const getCannyToken = async () => api.post(routes.api.user.embedFeedbackToken)

export const verifyEmail = async (token: string) =>
  api.post(routes.api.user.verifyEmail, { token })

export const createBillingPortalSession = (
  plan?: SubscriptionPlan,
  updatePaymentDetails?: boolean,
) =>
  api.post<{ url: string }, { plan?: SubscriptionPlan; updatePaymentDetails?: boolean }>(
    routes.api.user.createBillingPortalSession,
    { plan, updatePaymentDetails },
  )

export const useAppSettings = () => useFetch<SettingsInterface>(routes.api.user.settings)

export const collectGuestEmail = async (email: string, promo?: string, ref?: string) =>
  api.post(routes.api.user.collectGuestEmail, { email, promo, ref })

export const requestHelp = async () => api.post(routes.api.user.requestHelp)
