import React from 'react'
import styled from 'styled-components'
import Box from '@ui/Box'
import NextHead from 'next/head'

const WhyWe = styled(Box)`
  margin-top: 200px;

  @media (max-width: 768px) {
    margin-top: 100px;
  }
`

const Title = styled.h2`
  font-size: 70px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 50px;
  }
`

const Content = styled.div``

const ItemContent = styled.div`
  margin: 0 20px 0 0;
  max-width: 450px;

  @media (max-width: 768px) {
    margin: 0;
    max-width: 100%;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 200px;

  &:nth-child(even) {
    flex-direction: row-reverse;

    ${ItemContent} {
      margin: 0 0 0 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;

    &:nth-child(even) {
      flex-direction: column;
      ${ItemContent} {
        margin: 0;
      }
    }
  }
`

const ItemTitle = styled.h3`
  font-size: 56px;
  font-weight: 400;
  color: #40e0ec;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`

const ItemText = styled.div`
  font-size: 20px;
  margin-top: 40px;
`

const ItemImg = styled.img`
  max-width: 760px;
  border-radius: 20px;

  @media (max-width: 1000px) {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    margin: 30px 0 0 0;
    max-width: 100%;
  }
`

const ItemVideo = styled.video`
  max-width: 760px;
  border-radius: 20px;
  width: 100%;
  height: auto;

  @media (max-width: 1000px) {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    margin: 30px 0 0 0;
    max-width: 100%;
  }
`

const WhyWeComponent = () => {
  return (
    <>
      <NextHead>
        <link rel="preload" href="/img/landing/ab.png" as="image" />
        <link rel="preload" href="/img/landing/modern-design.png" as="image" />
        <link rel="preload" href="/img/landing/analytics.png" as="image" />
        <link rel="preload" href="/img/landing/extras.png" as="image" />
      </NextHead>
      <WhyWe id="why-choose-us">
        <Title>Why choose us?</Title>

        <Content>
          <Item>
            <ItemContent>
              <ItemTitle>Modern designs</ItemTitle>
              <ItemText>
                Discover the convenience of modern, read-to-use designs that can be easily
                customised to match your unique style and brand. <br /> <br /> Enjoy the
                flexibility to use a variety of icons, choose a subscription period,
                change currency, and highlight the most popular plan.
              </ItemText>
            </ItemContent>
            <ItemImg src="/img/landing/modern-design.png" />
          </Item>
          <Item>
            <ItemContent>
              <ItemTitle>A/B testing</ItemTitle>
              <ItemText>
                Increase your conversion rates with A/B testing. Create two pricing table
                options, run A/B tests, and see which one brings in more customers.
              </ItemText>
            </ItemContent>
            <ItemImg src="/img/landing/ab.png" />
          </Item>
          <Item>
            <ItemContent>
              <ItemTitle>Analytics</ItemTitle>
              <ItemText>
                Track the number of clicks on specific pricing plan options and total
                views easily with our analytics tool. Integrate with Google Analytics to
                access the data within your account.
              </ItemText>
            </ItemContent>
            <ItemImg src="/img/landing/analytics.png" />
          </Item>
          <Item>
            <ItemContent>
              <ItemTitle>AI Wizard</ItemTitle>
              <ItemText>
                Our AI Wizard makes it easy to create feature descriptions for your
                pricing plans. <br />
                <br />
                With just a few clicks, you'll get plan names, feature descriptions, and
                pricing for each plan, tailored to your industry, competitors and so on.
              </ItemText>
            </ItemContent>
            <ItemVideo
              loop
              autoPlay
              controls
              muted
              poster="https://enhanci-widgets.s3.eu-west-2.amazonaws.com/static/enhanci_ai_wizard.png"
            >
              <source
                src="https://enhanci-widgets.s3.eu-west-2.amazonaws.com/static/enhanci_ai_wizard.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </ItemVideo>
          </Item>
          <Item>
            <ItemContent>
              <ItemTitle>Extras</ItemTitle>
              <ItemText>
                Grab your users' attention with a plan comparison feature that showcases
                the unique benefits of each plan.
                <br />
                <br />
                Plus, offer responses to frequently asked questions through the FAQ
                section.
              </ItemText>
            </ItemContent>
            <ItemImg src="/img/landing/extras.png" />
          </Item>
        </Content>
      </WhyWe>
    </>
  )
}

export default WhyWeComponent
